import React from 'react'
// import './MainLayout.scss'
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import Navbar from '../../components/layouts/Navbar'
import theme from '../../utils/theme';
import { ToastContainer } from 'react-toastify';
// import {DataContext} from './../../context/DataContext'

export default function MainLayout(props) {
    const { children, enableSticky = false, withMargin = false } = props;
    return (
        <ThemeProvider 
        theme={theme}>
            <CssBaseline />
            <Navbar enableSticky={enableSticky} />
            {(!enableSticky && withMargin) && <div className='spacing'></div>}
            {children}
            <ToastContainer />
        </ThemeProvider>
    )
}