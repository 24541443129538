import { createMuiTheme } from '@material-ui/core/styles'; 

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E15311',
      // light:''
      // dark:''
      contrastText: '#fff',
    },
    secondary: {
      main: '#000000',
      contrastText: '#fff',
    },

    // type:'dark'
  }, 
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30,
        textTransform:'capitalize',
        fontWeight:'bold'
      }, 
    }, 
  },
  
});

export default theme;