import React, { useContext, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  ListItemText,
  makeStyles,
  Drawer,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import Languages from './../Languages'
import { navigate } from 'gatsby';

const DrawerComponent = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const useStyles = makeStyles(theme => ({
    drawerContainer: {
      background: theme.palette.primary.main,
    },
    iconButtonContainer: {
      color: 'white',
    },

    optionsContainer: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center'
    },

    menuIconToggle: {
      fontSize: '2rem',
    },
    itemText: {
      color: theme.palette.primary.contrastText
    }
  }));

  const [openDrawer, setOpenDrawer] = useState(false);

  //Css
  const classes = useStyles();
  return (
    <>
      <Drawer
        anchor='right'
        classes={{ paper: classes.drawerContainer }}
        onClose={() => setOpenDrawer(false)}
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}>
        <List

        >
          <ListItem divider button onClick={() => {
            navigate(language === 'en' ? `/${language}` : `/`);
            setOpenDrawer(false);
          }}>
            <ListItemIcon>
              <ListItemText className={classes.itemText}>{t('Home')}</ListItemText>
            </ListItemIcon>
          </ListItem>

          <ListItem divider button onClick={() => {
            navigate(language === 'en' ? `/${language}/covid19-protocol` : `/covid19-protocol`)
            setOpenDrawer(false)
          }}>
            <ListItemIcon>
              <ListItemText className={classes.itemText}>{t('Covid')}</ListItemText>
            </ListItemIcon>
          </ListItem>

          <ListItem divider button onClick={() => {
            navigate(language === 'en' ? `/${language}/contact` : `/contact`)
            setOpenDrawer(false)
          }}>
            <ListItemIcon>
              <ListItemText className={classes.itemText}>{t('Contact')}</ListItemText>
            </ListItemIcon>
          </ListItem>
          <ListItem divider button onClick={() => {
            navigate(language === 'en' ? `/${language}/reserve` : `/reserve`);
            setOpenDrawer(false)
          }}>
            <ListItemIcon>
              <ListItemText className={classes.itemText}>{t('Reserve')}</ListItemText>
            </ListItemIcon>
          </ListItem>
        </List>
      </Drawer>
      {/* Since this is inside our toolbar we can push it to the end of the toolbar */}
      <div className={classes.optionsContainer}>
        <Languages />
        <IconButton
          className={classes.iconButtonContainer}
          onClick={() => setOpenDrawer(!openDrawer)}
          disableRipple>
          <MenuIcon className={classes.menuIconToggle} />
        </IconButton>
      </div>
    </>
  );
};

export default DrawerComponent;
