import React, { useState, useEffect , useContext} from 'react'; 
import {
  AppBar,
  Tabs,
  Toolbar,
  Tab,
  Button, 
  useMediaQuery,
  useTheme,
  Container
} from '@material-ui/core'; 
import { Link, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';

import DrawerComponent from './DrawerComponent/DrawerComponent';
import Languages from './Languages'
import {navigate} from 'gatsby'
import {DataContext} from './../../../context/DataContext';
import logo from '../../../images/logo.png'

export default function Navbar({enableSticky=false}) {
  const [headerBg, setHeaderBg] = useState(enableSticky?'transparent':'secondary');
  // const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const {language} = useContext(I18nextContext);
  const {indexTab, setIndexTab} = useContext(DataContext);
  //Boolean(anchorEl) This is use to convert a null value in to a boolean
  //anchorEl Is us to set the position of the menu 

  const theme = useTheme(); //Get a copy of our default theme in our component so that we can access the breakpoints and pass the useMediaQuery

  const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

  //Functions
  const handleClickTab = (e, newValue) => {
    //The second value contains the current index
    setIndexTab(newValue);
  };

  // const handleOpenMenu = e => {
  //   setAnchorEl(e.currentTarget);
  // };
  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    if (enableSticky&&typeof window !== `undefined`&&typeof document !== `undefined`) {
      const handleScroll = () => {
        const show = window.scrollY > 30
        if (show) {
          setHeaderBg('secondary')
        } else {
          setHeaderBg('transparent')
        }
      }
      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      } 
    }
  }, [enableSticky])

  return (
    <>
      <AppBar elevation={0} color={headerBg}>
        <Container>
          <Toolbar
            className={'toolbar'}
          >
            <Link to='/'>
              <img 
              src={logo} 
              alt='logo'
              width='105px'
              />
            </Link>
            {isMatch ? (
              <>
                <DrawerComponent />
              </>
            ) : (
              <>
                <Tabs
                  onChange={handleClickTab}
                  className={'tabs-container'}
                  
                  value={indexTab}>
                  <Tab
                    disableRipple
                    // icon={<FiBookOpen className={classes.icons} />}
                    className='tab'
                    label={t('Home')}
                    onClick={()=> navigate(language==='en'?`/${language}`:`/`)}
                  /> 
                  <Tab
                    disableRipple
                    className='tab'
                    label={t('Covid')}
                    onClick={()=> navigate(language==='en'?`/${language}/covid19-protocol`:`/covid19-protocol`)}
                  />
                  <Tab
                    disableRipple
                    className='tab'
                    label={t('Contact')}
                    onClick={()=> navigate(language==='en'?`/${language}/contact`:`/contact`)}
                  />
                </Tabs>
                <Languages />
                <Button
                  aria-controls='menu'
                  // onMouseOver={handleOpenMenu} 
                  variant='contained'
                  color='primary'
                  onClick={()=>{ 
                    setIndexTab(3);
                    navigate(language==='en'?`/${language}/reserve`:`/reserve`);
                  }}
                >
                  {t('Reserve')}
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>  
    </>
  );
}
