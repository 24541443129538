import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'
import English from './../../../../images/en.png'
import Spanish from './../../../../images/es.png'

export default function Languages() {
    const { languages, changeLanguage } = useI18next();
    return (
        <ul className="languages">
            {languages.map((lng) => (
                <li key={lng}>
                    <a 
                        onClick={(e) => {
                            e.preventDefault();
                            changeLanguage(lng);
                        }}>
                        <img src={lng === 'en' ? English : Spanish} alt={lng} />
                    </a>
                </li>
            ))}
        </ul>
    )
}
